import Vue from 'vue'
import Vuex from 'vuex'
import moment from "moment"

import { API } from 'aws-amplify'
import { listItems, getItem } from '@/graphql/queries'
import { createItem, updateItem } from "@/graphql/mutations";

import { shortListItems } from '@/graphql/custom-queries'

import LogRocket from 'logrocket';
import { StringUtils } from './utils/StringUtils'
import { StoreUtils } from './utils/StoreUtils';

// import * as dataService from "./services/DataService";

// import receiverTypes from "@/../_db/receiverTypes";
// import tags from "@/../_db/tags";
// import calls from "@/../_db/calls";

import createPlugin from 'logrocket-vuex'

const logrocketPlugin = createPlugin(LogRocket)

Vue.use(Vuex)

export default new Vuex.Store({
        // strict: process.env.NODE_ENV !== 'production',
        plugins: [logrocketPlugin],
        state: {
                version: '0.23.0',
                minResolution: 0,
                node_env: process.env.NODE_ENV,
                isAdmin: null,
                debug: {
                        log: false,
                        filtersEnabled: false,
                },
                user: {
                        id: null,
                },
                userConfig: {
                        dataTableItemsView: true,
                },
                userName: 'test@beepartner.cz',
                accessToken: null,

                saved: true,
                error: false,
                editMode: true,

                templateItem: {
                        "isValid": 0,
                        "id": null,
                        "ic": "", //
                        "name": "", // friendly name
                        "parts": "0", // extenze
                        "amount": "1", // kusů
                        "state": null, // stav
                        "image": null,
                        "comment": null,
                },

                items: [],
                item: null,
                printProtocol: false,
        },
        getters: {
                isSigned(state) {
                        return !!state.userName
                },
                urlQueryTags(state) {
                        let result = []
                        if (state.urlQuery && state.urlQuery.tag) {
                                result.push(...state.urlQuery.tag.split(','))
                        }
                        return result
                },
        },
        mutations: {
                token(state, token) {
                        state.accessToken = token
                },
                // fetchClients(state, clients) {
                // 	state.clients = clients
                // },
                fetchItem(state, item) {
                        state.item = item
                },
                fetchItems(state, items) {
                        console.log('fetch items')
                        state.items = items
                },
                addItem(state, item) {
                        state.items.push(item)
                        state.item = item
                },
                updateItem(state, item) {
                        if (!item) return

                        let index = state.items.findIndex(x => x.id == item.id)
                        if (index > -1) {

                                if (item.isValid == 1) {
                                        Vue.set(state.items, index, item)
                                }
                                else {
                                        state.items.splice(index,1)
                                }
                        }
                        else {
                                state.items.push(item)
                        }
                }
        },
        actions: {
                addItem({ state, commit }) {
                        let item = JSON.parse(JSON.stringify(state.templateItem));
                        if (!item.createdBy) {
                                item.createdBy = state.userName
                        }
                        item.updatedBy = state.userName
                        let tmpDate = new Date()
                        item.id = tmpDate.getTime()
                        return new Promise((resolve) => {
                                API.graphql({
                                        query: createItem,
                                        variables: { input: item },
                                }).then((res) => {
                                        const item = res.data.createItem
                                        commit('addItem', item)
                                        StoreUtils.saveOk(res, 'addItem')
                                        resolve(item)
                                }).catch((err) => {
                                        StoreUtils.saveError(err, 'addItem')
                                })
                        })
                },
                updateItem({ state }, { item, isValid }) {
                        console.log('action updateItem')
                        console.log(isValid)
                        item.isValid = isValid
                        // fix / workaround for "not deleted items"
                        // if (isValid == -1) {
                        // 	state.deletedProjects.push(project.id)
                        // }

                        if (!item.createdBy) {
                                item.createdBy = state.userName
                        }
                        item.updatedBy = state.userName

                        this.commit("updateItem", item)

                        // Clone - needed to perform updates and not affect original object
                        let itemClone = JSON.parse(JSON.stringify(item));

                        API.graphql({
                        	query: updateItem,
                        	variables: { input: itemClone },
                        }).then((res) => {
                                StoreUtils.saveOk(res, 'updateItem')
                        	// eslint-disable-next-line no-console
                        	console.log('saved')
                        }).catch((err) => {
                                StoreUtils.saveError(err, 'updateItem')
                        })
                },
                async fetchItems({ state, commit }) {
                        if (state.items && state.items.length > 0) return
                        let items = []
                        let nextToken = null
                        let loaded = false
                        try {
                                while (!loaded) {

                                        const res = await API.graphql({
                                                query: listItems,
                                                variables: {
                                                        limit: 1000,
                                                        nextToken: nextToken
                                                }
                                        })

                                        nextToken = res.data.listItems.nextToken
                                        const tmp = res.data.listItems.items
                                        if (!nextToken) {
                                                loaded = true
                                        }
                                        if (tmp) {
                                                for (let item of tmp) {
                                                // if (item.isValid != -1 && !state.deletedProjects.includes(item.id)) {
                                                        if (item.isValid != -1) {
                                                                items.push(item)
                                                        }
                                                }
                                        }
                                }
                        }
                        catch(err) {
                                StoreUtils.fetchError(err, 'fetchItems')
                        }
                        commit('fetchItems', items)
                },
                fetchItem({ state, commit }, id) {
                        API.graphql({
                                query: getItem,
                                variables: {
                                        id: id,
                                }
                        }).then((res) => {
                                let item = res.data.getItem
                                commit('fetchItem', item)
                        }).catch((err) => {
                                StoreUtils.fetchError(err, 'fetchItem')
                        })

                },
        }
})

