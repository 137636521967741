/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVersion = /* GraphQL */ `
  mutation CreateVersion(
    $input: CreateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    createVersion(input: $input, condition: $condition) {
      id
      ver
      createdAt
      updatedAt
    }
  }
`;
export const updateVersion = /* GraphQL */ `
  mutation UpdateVersion(
    $input: UpdateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    updateVersion(input: $input, condition: $condition) {
      id
      ver
      createdAt
      updatedAt
    }
  }
`;
export const deleteVersion = /* GraphQL */ `
  mutation DeleteVersion(
    $input: DeleteVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    deleteVersion(input: $input, condition: $condition) {
      id
      ver
      createdAt
      updatedAt
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      isValid
      ic
      name
      description
      parts
      amount
      state
      image
      thumbImage
      comment
      textInput
      verified
      verifiedBy
      openAt
      verifiedAt
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      isValid
      ic
      name
      description
      parts
      amount
      state
      image
      thumbImage
      comment
      textInput
      verified
      verifiedBy
      openAt
      verifiedAt
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      isValid
      ic
      name
      description
      parts
      amount
      state
      image
      thumbImage
      comment
      textInput
      verified
      verifiedBy
      openAt
      verifiedAt
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
