import { Translations } from "@aws-amplify/ui-components";
// https://github.com/aws-amplify/amplify-js/issues/7233

export const dict = {
        cs: {

                [Translations.USERNAME_LABEL]: "Uživatelské jméno",
                [Translations.PASSWORD_LABEL]: "Heslo",
                // sign-in form
                [Translations.SIGN_IN_ACTION]: "Přihlásit",
                [Translations.SIGN_IN_HEADER_TEXT]: "Vítejte",
                [Translations.USERNAME_PLACEHOLDER]: "Zadejte uživatelské jméno",
                [Translations.PASSWORD_PLACEHOLDER]: "Zadejte heslo",
                [Translations.FORGOT_PASSWORD_TEXT]: " ",
                [Translations.RESET_PASSWORD_TEXT]: " ",
                "User does not exist.": "Neznámé uživatelské jméno",
                "Incorrect username or password.": "Neplatné uživatelské jméno nebo heslo",

                // "Sign in to your account": "Vítejte",
                // "Forgot your password?": " ",
                // "Reset password": " ",
                // "Custom auth lambda trigger is not configured for the user pool.": "Neplatný email nebo heslo",
                // "Username cannot be empty": "Neplatný email nebo heslo",
                // "Enter your username": "Zadejte uživatelské jméno",
                // "Enter your password": "Zadejte heslo",
                // // sign-out
                // "Sign Out": "Odhlásit",
                // // reset
                // "Change Password": "Změna hesla",
                // "Change": "Změnit",
                // // "Cannot read property 'completeNewPasswordChallenge' of undefined": "",
                // // reset
                // "Reset your password": "Reset hesla",
                // "Back to Sign In": "Zpět",
                // "Username/client id combination not found.": "Neznámý email",
                // "Send Code": "Zaslat kód na email",
                // "Verification code": "Verifikační kód",
                // "Enter code": "Zadejte kód z emailu",
                // "New password": "Nové heslo",
                // "Enter your new password": "Zadejte nové heslo",
                // "Submit": "Odeslat",
                // "Confirmation code cannot be empty": "Zadejte verifikační kód (byl zaslán na Vaši emailovou adresu).",
                // "Password cannot be empty": "Zadejte heslo.",
                // "Invalid verification code provided, please try again.": "Neplatný verifikační kód.",
                // "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": "Heslo musí mít více než 5 znaků!",
                // "Password does not conform to policy: Password not long enough": "Heslo musí mít minimálně 8 znaků!",
                // "Attempt limit exceeded, please try after some time.": "Překročen počet pokusů o přihlášení! Zkuste to za chvíli znova.",
        },
};
