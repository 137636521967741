import store from '../store';

// import { DateUtils } from "@/utils/DateUtils";

export class StoreUtils {
        static saveOk(res, source) {
                store.state.saved = true
                store.state.error = false
        }

        static saveError(err, source) {
                console.log(source)
                console.log(err)
                store.state.error = true
                if (err.errors && err.errors[0] && err.errors[0].message) {
                        store.state.error = err.errors[0].message
                }
        }

        static fetchError(err, source) {
                console.log(source)
                console.log(err)
                store.state.error = err
                if (err && err.errors && err.errors[0] && err.errors[0].message) {
                        store.state.error = err.errors[0].message
                }
        }

        static appError(err, source) {
                console.log(source)
                console.log(err)
                store.state.error = true
                if (err) {
                        store.state.error = 'AppErr: ' + err
                }
        }

        static reportError(err, source) {
                console.log(source)
                console.log(err)
                store.state.error = true
                if (err) {
                        store.state.error = err
                }
        }
}
