import Vue from 'vue';
import Router from 'vue-router';
// import Dashboard from './components/Dashboard.vue';
import store from './store'

Vue.use(Router);

export default new Router({
        routes: [
                {
                        path: '/',
                        component: () => import('./components/List.vue'),
                        beforeEnter: checkInputIntro,
                },
                {
                        path: '/testface/',
                        component: () => import('./components/TestFace.vue'),
                        beforeEnter: checkInputIntro,
                },
                {
                        path: '/test/',
                        component: () => import('./components/Test.vue'),
                        beforeEnter: checkInputIntro,
                },
                {
                        path: '/item/:id',
                        name: 'item',
                        props: true,
                        component: () => import('./components/Item.vue'),
                        beforeEnter: checkInput,
                },
                {
                        path: '/print',
                        component: () => import('./components/Print.vue'),
                        beforeEnter: checkInputIntro,

                },
                // {
                //         path: '/zasady-zpracovani-dat',
                //         name: 'zasady-zpracovani-dat',
                //         component: () => import('./components/ZasadyZpracovaniDat.vue'),
                //         beforeEnter: checkInput,
                // },
        ],
        scrollBehavior() {
                return { x: 0, y: 0 };
        }
});

//
// Validate user is logged-in and in the authorised users list
//
async function checkInputIntro(to, from, next) {
        // if (!store.state.isLoggedIn || !store.state.accessToken) {
        //         await store.dispatch("authenticate");
        // }
        // if (store.state.isLoggedIn) {
        // 	next('/beeinput/match')
        // }
        // else {
        next()
        // }
}

async function checkInput(to, from, next) {
        if (to.query) {
                store.state.urlQuery = to.query
        }
        // If no user object - redirect to Login
        // if (!store.state.isLoggedIn || !store.state.accessToken) {
        // 	await store.dispatch("authenticate");
        // }
        // if (!store.state.isLoggedIn) {
        // 	next('/')
        // } else {
        next()
        // }
}

async function checkInputAdmin(to, from, next) {
        if (store.state.isAdmin) {
                next()
        }
        else {
                next('/')
        }
}
