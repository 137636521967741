<template>
    <div :class="containerClass" @click="onWrapperClick">
        <Toast />
        <AppTopBar v-if="!$store.state.printProtocol" :show-stat="currentResolution>=$store.state.minResolution && currentWindowWidth>=$store.state.minResolution" @menu-toggle="onMenuToggle" />

        <a v-if="displayInfoDialog == false" class="floatChatIcon" @click="displayInfoDialog = true" />
        <a v-if="displayInfoDialog == true" class="floatChatIcon" @click="displayInfoDialog = false" />


        <!-- <Dialog :visible.sync="displayInfoDialog" position="bottomright" class="chatDialog" content-style="chatDialogContent">
            <template #header>
                <img src="../images/v2/robee_logo.svg">
            </template>

            <h2>Co mám dělat, když něco nefunguje ?</h2>
            Kontaktujte prosím zákaznickou podporu:
            <a href="mailto:podpora@robee.cz" target="_blank">podpora@robee.cz</a><br>
            nebo na telefonním čísle <a href="tel:+420 734 177 994">+420 734 177 994</a>
        </Dialog> -->

        <!-- Cookies Agreenment -->

        <Dialog :visible.sync="showCookieDialog" :position="'bottom'" :modal="true" :closable="false" header="Nastavení souborů cookies">
            <div class="p-grid" style="max-width:700px;">
                <div class="p-col-12">
                    <Checkbox id="mandatory" v-model="cookiesMandatoryApproved" :binary="true" :disabled="true" class="p-mr-4" />
                    <label for="mandatory">Nezbytné technické</label>
                </div>
                <div class="p-col description">
                    Bez těchto cookies nebudou stránky fungovat správně, například nám umožní snížit počet výzev pro udělení souhlasu.
                </div>
                <div class="p-col-12">
                    <Checkbox id="analytics" v-model="cookiesAnalyticsApproved" :binary="true" class="p-mr-4" />
                    <label for="analytics">Analytické</label>
                </div>
                <div class="p-col description">
                    Díky těmto cookies můžeme webové stránky zlepšovat. abyste rychleji dosáhli svého cíle.
                </div>

                <!-- <div class="p-col-12">

                        <Checkbox id="useSettings" v-model="cookiesSettingsApproved" class="p-mr-4" :binary="true" />

                        <label for="useSettings">Personalizované</label>

                </div>

                <div class="p-col description">

                        Tento druh nám umožní snížit počet výzev pro udělění souhlasu.

                </div> -->
            </div>

            <router-link to="/zasady-zpracovani-dat">
                <Button label="Zásady zpracování cookies - více informací o cookies" class="p-button-link p-px-0 p-mt-2" icon="pi pi-info-circle" />
            </router-link>

            <p class="">
                Nastavení můžete kdykoliv později změnit v levém postranním menu.
            </p>

            <template #footer>
                <!-- <Button label="Nesouhlasím s ukládání cookies" class="p-button-danger p-button-text" icon="pi pi-times" @click="cookiesDenied();" /> -->
                <Button label="Souhlasím s vybranými cookies" class="p-button-text" icon="pi pi-user" @click="cookiesApprove();" />
                <Button label="Souhlasím se všemi cookies" icon="pi pi-check" autofocus @click="cookiesApprove('all');" />
            </template>
        </Dialog>

        <transition name="layout-sidebar">
            <div v-show="isSidebarVisible()" :class="sidebarClass" @click="onSidebarClick">
                <div class="layout-logo" style="color:white">
                    <router-link to="/">
                        <img :src="logo" alt="Logo" class="logo">
                    </router-link>
                    <br>
                    <br>
                    <br>
                    Muzeum v{{ $store.state.version }}
                    <br>
                    build:{{ build }}
                    <br>
                    <br>BeePartner a.s. <span @click="$store.state.debug.log = !$store.state.debug.log">(c)</span> 2022
                    <br>
                    <br>
                    <b>{{ $store.state.userName }}</b>
                    <br>
                    <br>
                    <Button
                        label="Změnit nastavení cookies"
                        class="p-button p-mt-4"
                        @click="cookiesAnswered=false"
                    />
                    <br>
                    <!-- <amplify-sign-out v-if="$store.state.userName" /> -->
                </div>

                <!-- <AppProfile /> -->
                <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
            </div>
        </transition>

        <div :class="mainLayoutClasses">
            <div v-if="!isSigned && this.$router.app._route.path == '/zasady-zpracovani-dat'">
                <router-view />
            </div>

            <div v-else>
                <Dialog :visible.sync="displayRefreshDialog" header="K dispozici je nová verze">
                    <p class="p-m-0">
                        Je připravena nová verze aplikace. Provést update nyní (přijdete o neuložená data)?
                    </p>
                    <template #footer>
                        <Button
                            label="Odložit o 5 minut"
                            icon="pi pi-times"
                            class="p-button-text"
                            @click="displayRefreshDialog=false"
                        />
                        <Button label="Provést nyní" icon="pi pi-check" autofocus @click="refreshApp" />
                    </template>
                </Dialog>
                <!-- <amplify-authenticator :auth-config="{ signInConfig: { isSignUpDisplayed: false } }"> -->
                <!-- <amplify-sign-in slot="sign-in" hide-sign-up="true" /> -->
                <router-view v-if="isSigned" @cookiedialog="resetCookieAnswer" />
                <!-- </amplify-authenticator> -->

                <!-- <div v-if="!$store.getters.isSigned" class="introLogo">
                    <img src="../images/robee_logo_300.png">
                </div> -->
            </div>


            <!-- <amplify-authenticator style="visibility:hidden;display:none;height:0px;margin:0px;padding:0px;border:0px;">
                <amplify-sign-in slot="sign-in" hide-sign-up="false" />
                <amplify-forgot-password slot="forgot-password" />
                <router-view />
            </amplify-authenticator> -->


        <!-- <AppFooter /> -->
        </div>
    </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
// import AppProfile from "./AppProfile.vue";
import AppMenu from "./AppMenu.vue";
// import AppFooter from "./AppFooter.vue";
import build from "./assets/version";

import LogRocket from "logrocket";

import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { API, Auth } from "aws-amplify";
import { getVersion } from "@/graphql/queries";

function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');

        for(let i = 0; i <ca.length; i++) {

                let c = ca[i];
                while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                }

                if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                }
        }

        return "";
}

export default {
        components: {
                AppTopBar: AppTopBar,
                // AppProfile: AppProfile,
                AppMenu: AppMenu,
        // AppFooter: AppFooter,
        },
        data() {
                return {
                        build: build,
                        initialVersionCheck: true,
                        gdprCheck: false,
                        gdpr: false,
                        cookiesAnswered: false,
                        cookiesMandatoryApproved: true,
                        cookiesAnalyticsApproved: false,
                        cookiesSettingsApproved: false,
                        initLogRocket: false,
                        loginCode: "",
                        loginName: "",
                        loginPassword: "",
                        loginError: null,
                        loginInProgress: false,
                        loginPanel: true,
                        loginConfirmPanel: false,
                        resendCodePanel: false,
                        resetPasswordPanel: false,
                        createAccountPanel: false,
                        newUserPanel: false,
                        displayRefreshDialog: false,
                        displayInfoDialog: false,
                        layoutMode: "overlay",
                        layoutColorMode: "dark",
                        staticMenuInactive: false,
                        overlayMenuActive: false,
                        mobileMenuActive: false,
                        menu: [],
                        windowWidth: window.innerWidth,
                };
        },
        computed: {
                showCookieDialog(){
                        if (this.$router.app._route.path == "/zasady-zpracovani-dat")
                        {
                                return false;
                        }
                        return this.cookiesNotApproved;
                },
                cookiesNotApproved(){
                        return !this.cookiesAnswered
                },
                currentResolution()
                {
                        return window.screen.availWidth;
                },
                currentWindowWidth()
                {
                        return this.windowWidth;
                },
                mainLayoutClasses()
                {
                        return [
                                "layout-main",
                                {
                                        "loginCenter": (!this.$store.getters.isSigned && this.$router.app._route.path != '/zasady-zpracovani-dat')
                                }
                        ]
                },
                containerClass() {
                        return [
                                "layout-wrapper",
                                {
                                        "layout-overlay": this.layoutMode === "overlay",
                                        "layout-static": this.layoutMode === "static",
                                        "layout-static-sidebar-inactive":
                                                this.staticMenuInactive && this.layoutMode === "static",
                                        "layout-overlay-sidebar-active":
                                                this.overlayMenuActive && this.layoutMode === "overlay",
                                        "layout-mobile-sidebar-active": this.mobileMenuActive,
                                },
                        ];
                },
                sidebarClass() {
                        return [
                                "layout-sidebar",
                                {
                                        "layout-sidebar-dark": this.layoutColorMode === "dark",
                                        "layout-sidebar-light": this.layoutColorMode === "light",
                                },
                        ];
                },
                logo() {
                        return "/assets/layout/images/hero-logo.png";
                        // return (this.layoutColorMode === 'dark') ? "assets/layout/images/logo-white.svg" : "assets/layout/images/logo.svg";
                },
                isSigned() {
                        return this.$store.getters.isSigned;
                },

        },
        watch: {
                $route() {
                        this.menuActive = false;
                        this.$toast.removeAllGroups();
                },
        },
        mounted() {
                if (this.cookiesAnswered && this.cookiesAnalyticsApproved)
                {
                        // this.$gtag.event('generate_lead', { method: 'Google' })
                        // this.$gtag.event('screen_view', { size: '666'});
                }
        },
        async created() {
                // disable logrocket if localhost or forced via logoff
                let protocol = location.protocol
                let slashes = protocol.concat("//")
                let hostUrl = slashes.concat(window.location.host)

                if (window.location.search && window.location.search.includes('logoff')) {
                        this.initLogRocket = false
                }
                if (hostUrl && hostUrl.includes('localhost')) {
                        this.initLogRocket = false
                        this.initialVersionCheck = false
                }
                // ===================>

                // loadFromCookies if any and feed cookie vars
                var dlg_cook_answered = getCookie("dlg_cook_answered");
                var dlg_cook_analytics = getCookie("dlg_cook_analytics");
                if (dlg_cook_answered == "true")
                {
                        // console.log("dlg_cook_answered: TRUE");
                        this.cookiesAnswered = dlg_cook_answered;
                }
                if (dlg_cook_analytics == "true")
                {
                        // console.log("dlg_cook_analytics: TRUE");
                        this.cookiesAnalyticsApproved = dlg_cook_analytics;
                }
                if (this.cookiesAnswered && this.cookiesAnalyticsApproved)
                {
                        if (this.initLogRocket)
                        {
                                // LogRocket.init('khecro/slu');
                        }
                        // this.$gtag.optIn();
                }

                // eslint-disable-next-line no-console
                console.log(`v${this.$store.state.version} #${build}`)
                this.checkVersion();
                setInterval(this.checkVersion, 5 * 60 * 1000);
                onAuthUIStateChange((authState, authData) => {
                        if (authState === "signedin" && !this.$store.state.userName) {
                                let groups = authData.signInUserSession.idToken.payload['cognito:groups']
                                if (groups && groups.length > 0) {
                                        this.$store.state.isAdmin = groups.includes('admin')
                                }
                                this.$store.state.userName = authData.attributes.email;
                                this.$store.dispatch('fetchUser')
                                if (this.cookiesAnswered && this.cookiesAnalyticsApproved && this.initLogRocket)
                                {
                                        LogRocket.identify(this.$store.state.userName);
                                }
                        }
                        if (authState === "signedout") {
                                this.$store.state.userName = null;
                                this.$store.state.isAdmin = null;
                                this.refreshApp();
                        }
                });
        },
        beforeUpdate() {
                if (this.mobileMenuActive)
                        this.addClass(document.body, "body-overflow-hidden");
                else this.removeClass(document.body, "body-overflow-hidden");
        },
        beforeDestroy() {
                window.removeEventListener('resize', this.onResize); // update actual window width
        },
        beforeCreate: function () {
        // Ensure that on app boot, we check if the user is authenticated or not
        // this.$store.dispatch("authenticate");
        },
        mounted: function () {
                // Ensure that on app boot, we check if the user is authenticated or not
                // this.$store.dispatch("authenticate");

                // var style = document.createElement( 'style' )
                // style.innerHTML = '.the-class-name { property-name: my-value; }'
                // host.shadowRoot.appendChild( style )

                // setTimeout( ()=> {


                //         var style = document.createElement( 'style' )
                //         style.innerHTML = ':host {--color: var(--amplify-background-color);}'
                //         document.getElementById("amplifyCustom").shadowRoot.lastChild.getElementsByTagName("amplify-auth-fields")[0].shadowRoot.lastChild.getElementsByTagName("amplify-email-field")[0].shadowRoot.lastChild.shadowRoot.appendChild( style )

                //         style = document.createElement( 'style' )
                //         style.innerHTML = ':host {--color: var(--amplify-background-color);}'
                //         document.getElementById("amplifyCustom").shadowRoot.lastChild.getElementsByTagName("amplify-auth-fields")[0].shadowRoot.lastChild.getElementsByTagName("amplify-password-field")[0].shadowRoot.lastChild.shadowRoot.appendChild( style )

                // } , 2000)
                // this.forceAmplifyTheme();

                this.$nextTick(() => {
                        window.addEventListener('resize', this.onResize);
                })

        },
        methods: {
                resetCookieAnswer() {
                        this.cookiesAnswered = false;
                        console.log("received");
                },
                cookieDialog() {
                        console.log("cought event")
                },
                cookiesApprove(state="user") {
                        if (state == "all")
                        {
                                this.cookiesAnalyticsApproved = true;
                                this.cookiesSettingsApproved = true;
                        }

                        if (this.cookiesAnalyticsApproved)
                        {
                                // LR could be init multiple times as it has internal check if it has been already init or not
                                if (this.initLogRocket)
                                {
                                        // LogRocket.init('khecro/slu');
                                }

                                // this.$gtag.optIn();


                                // User has been already logged in
                                if (this.$store.state.userName)
                                {
                                        LogRocket.identify(this.$store.state.userName);
                                }

                        } else {

                                // ToDo deinit LogRocket session
                                // LogRocket.uninstall();
                                this.refreshApp(true);
                        }

                        this.cookiesAnswered = true;
                        setCookie("dlg_cook_analytics", this.cookiesAnalyticsApproved, 365);
                        setCookie("dlg_cook_answered", this.cookiesAnswered, 365);
                },
                onResize() {
                        this.windowWidth = window.innerWidth
                },
                async refreshApp() {
                        this.displayRefreshDialog = false;
                        window.location.reload(true);
                },
                hideAll() {
                        this.loginError = false
                        this.loginInProgress = false
                        this.loginPanel = false;
                        this.loginConfirmPanel = false;
                        this.resendCodePanel = false;
                        this.resetPasswordPanel = false;
                        this.createAccountPanel = false;
                },
                back() {
                        this.hideAll();
                        this.loginPanel = true;
                },
                async checkVersion() {
                        await API.graphql({
                                query: getVersion,
                                variables: { id: 1 },
                        }).then((res) => {
                                let localVer = this.$store.state.version;
                                localVer = localVer.substr(0, localVer.lastIndexOf("."));
                                let serverVer = res.data.getVersion.ver;
                                if (serverVer) {
                                        serverVer = serverVer.substr(0, serverVer.lastIndexOf("."));
                                        serverVer = parseInt(serverVer.substr(0, serverVer.lastIndexOf("."))) * 1000000 + parseInt(serverVer.substr(serverVer.lastIndexOf(".") + 1))
                                        localVer = parseInt(localVer.substr(0, localVer.lastIndexOf("."))) * 1000000 + parseInt(localVer.substr(localVer.lastIndexOf(".") + 1))
                                        if (serverVer > localVer) {
                                                if (this.initialVersionCheck) {
                                                        this.refreshApp();
                                                } else {
                                                        this.displayRefreshDialog = true;
                                                }
                                        }
                                }
                                this.initialVersionCheck = false;
                        });
                },
                onWrapperClick() {
                        if (!this.menuClick) {
                                this.overlayMenuActive = false;
                                this.mobileMenuActive = false;
                        }

                        this.menuClick = false;
                },
                async onMenuToggle() {
                        this.menuClick = true;

                        if (this.isDesktop()) {
                                if (this.layoutMode === "overlay") {
                                        this.overlayMenuActive = !this.overlayMenuActive;
                                } else if (this.layoutMode === "static") {
                                        this.staticMenuInactive = !this.staticMenuInactive;
                                }
                        } else {
                                this.mobileMenuActive = !this.mobileMenuActive;
                        }

                        event.preventDefault();
                },
                onSidebarClick() {
                        this.menuClick = true;
                },
                onMenuItemClick(event) {
                        if (event.item && !event.item.items) {
                                this.overlayMenuActive = false;
                                this.mobileMenuActive = false;
                        }
                },
                addClass(element, className) {
                        if (element.classList) element.classList.add(className);
                        else element.className += " " + className;
                },
                removeClass(element, className) {
                        if (element.classList) element.classList.remove(className);
                        else
                                element.className = element.className.replace(
                                        new RegExp(
                                                "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
                                                "gi"
                                        ),
                                        " "
                                );
                },
                isDesktop() {
                        return window.innerWidth > 1024;
                },
                isSidebarVisible() {
                        if (this.isDesktop()) {
                                if (this.layoutMode === "static")
                                        return !this.staticMenuInactive;
                                else if (this.layoutMode === "overlay")
                                        return this.overlayMenuActive;
                                else return true;
                        } else {
                                return true;
                        }
                },
                handleSubmit(e, method) {
                        if (e && e.key == "Enter") {
                                method();
                        }
                },
        },
};
</script>

<style lang="scss">
.p-col.description {flex-grow: unset;flex-basis:auto;color:#888;font-style:italic;padding-top:0;padding-bottom:20px;}
@import "./App.scss";

// .floatChatIcon {
//   position: fixed;
//   width: 60px;
//   height: 60px;
//   bottom: 40px;
//   right: 40px;
//   background-color: #00AA74;
//   color: #fff;
//   border-radius: 50px;
//   text-align: center;
//   box-shadow: 2px 2px 3px #999;
//   background:url('../images/v2/napoveda.svg') left top no-repeat;background-size: 60px 60px;
//   cursor:Pointer;
// }


</style>
