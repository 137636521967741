/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVersion = /* GraphQL */ `
  query GetVersion($id: ID!) {
    getVersion(id: $id) {
      id
      ver
      createdAt
      updatedAt
    }
  }
`;
export const listVersions = /* GraphQL */ `
  query ListVersions(
    $filter: ModelVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ver
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      isValid
      ic
      name
      description
      parts
      amount
      state
      image
      thumbImage
      comment
      textInput
      verified
      verifiedBy
      openAt
      verifiedAt
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isValid
        ic
        name
        description
        parts
        amount
        state
        image
        thumbImage
        comment
        textInput
        verified
        verifiedBy
        openAt
        verifiedAt
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
