<template>
    <div class="layout-topbar">
        <button class="p-link layout-menu-button" style="line-height: 1; z-index: 10;left-margin:50px;" @click="onMenuToggle">
            <span style="z-index: 10;width:50px">&nbsp;</span>
            <span class="pi pi-bars" style="z-index: 10"></span>
        </button>
        <span class="absolute-center p-text-center">
            <b class="p-d-none p-d-lg-block" style="font-size: 1.6em;  z-index: -10">DMS Muzeum</b>
            <b class="p-d-lg-none" style="font-size: 1.6em;  z-index: -10">DMS Muzeum</b>
        </span>
        <div class="layout-topbar-icons">
            <div
                v-if="$store.state.error"
                class="saved pi-blink"
                style="display:inline-block;text-align:center;width:100px;color:#ffcc12;"
                :title="$store.state.error"
            >
                <div class="pi pi-save" style="font-size:18px;color#ffcc12;" />
                <!-- <div class="pi pi-cloud-upload" style="font-size:18px;" /> -->
                <div>chyba !!</div>
            </div>
            <div
                v-if="$store.state.canEdit && !$store.state.error && $store.state.saved"
                class="saved"
                style="display:inline-block;text-align:center;width:100px;"
            >
                <div class="pi pi-save" style="font-size:18px;" />
                <div>
                    vše uloženo
                </div>
            </div>
            <div
                v-if="!$store.state.error && !$store.state.saved"
                class="saving"
                style="display:inline-block;text-align:center;width:100px;"
            >
                <!-- <ProgressSpinner stroke-width="8" style="width:18px;height:18px;" /> -->
                <div class="pi pi-spin-turbo pi-spinner" style="font-size:18px;color:#fff;" />
                <!-- <div class="pi pi-cloud-upload" style="font-size:18px;" /> -->
                <div>ukládám ...</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
        props: {
                showStat: Boolean
        },
        data() {
                return {
                        scrollTop: 0,
                };
        },
        computed: {
                currentScrollTop()
                {
                        return this.scrollTop;
                },
                topBarClasses(){
                        return [
                                "layout-topbar",
                                {
                                        // "scrolled": 1==1
                                        "scrolled": this.scrollTop != 0
                                }
                        ]
                }

        },
        mounted: function () {

                this.$nextTick(() => {
                        window.addEventListener('scroll', this.onScroll);
                })

        },
        beforeDestroy() {
                window.removeEventListener('resize', this.onScroll); // update actual window width
        },
        methods: {
                onMenuToggle(event) {
                        this.$emit("menu-toggle", event);
                },
                onScroll() {
                        this.scrollTop = (document.body.getBoundingClientRect()).top;
                },
                navigateHome(){
                        window.location.reload(false);
                }
        },
};
</script>

<style>
.absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 450px;
    transform: translate(-50%, -50%);
}
.container {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}
</style>