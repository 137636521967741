import moment from "moment"
export class StringUtils {
        // static isEmpty(value) {
        //         return !value || !value.trim()
        // }

        static capitalize(word) {
                return word.charAt(0).toUpperCase()
        }

        static includesInStringArray(ar, filter) {
                if (!ar) return false
                if (!filter) return false

                for (let s of ar) {
                        if (!s) {
                                continue
                        }
                        s = s.trim()
                        s = this.normalize(s)
                        if (s.includes(filter)) return true
                }
                return false
        }

        static normalize(s) {
                if (!s) return s
                return s.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        }

        // convert from YYYY-MM-DD to DD.MM.YYYY
        static convertDateString(s) {
                if (!s) return s
                let y = s.substr(0, 4)
                let m = s.substr(5, 2)
                let d = s.substr(8, 2)

                return d + "." + m + "." + y
        }

        static nameFromEmail(s) {
                if (!s) return s
                return s.substring(0, s.indexOf('@'))
        }

        static getCallState(call) {
                let now = moment()
                if(!call) return ""
                if(call.rysujeSe) return "Avízo"
                // if (!_dateFrom || !_dateTo) return ""

                let dateFrom = moment(call.DatumOd, "DD.MM.YYYY")
                let dateTo = moment(call.DatumDo, "DD.MM.YYYY")

                if (now < dateFrom) return "Plánuje se"
                if (now > dateTo) return "Ukončena"
                // if (now >= moment(dateTo).add('day', -14) && now <= dateTo) return "Bude končit"
                if (now >= dateFrom && now <= dateTo) return "Probíhá"
                return "Plánuje se"
        }

        static getCallStatusNum(status) {
                if (!status) return 0
                switch (status) {
                case "Ukončena":
                        return 1
                case "Avízo":
                        return 2
                case "Plánuje se":
                        return 3
                case "Probíhá":
                        return 4
                        // case "Bude končit":
                                // return 5
                }
                return -1
        }
}
