import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import VueGtag from "vue-gtag";
// import msal from 'vue-msal'

import VueJsonPretty from 'vue-json-pretty'
import { WebCam } from "vue-web-cam";

// Amplify
import Amplify, { Auth } from 'aws-amplify';
import { I18n } from 'aws-amplify';
import { dict } from './amplify-auth-locale';
// import AmplifyEventBus from '@aws-amplify/amplifyEventBus';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-vue';

// log rocket
import LogRocket from 'logrocket'

// prime vue
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
// import Accordion from 'primevue/accordion';
// import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
// import Carousel from 'primevue/carousel';
// import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
// import Chips from 'primevue/chips';
import Column from 'primevue/column';
// import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
// import Fieldset from 'primevue/fieldset';
// import FileUpload from 'primevue/fileupload';
// import FullCalendar from 'primevue/fullcalendar';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Listbox from 'primevue/listbox';
// import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
// import OrderList from 'primevue/orderlist';
// import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
// import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
// import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Steps from 'primevue/steps';
// import TabMenu from 'primevue/tabmenu';
// import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
// import TabView from 'primevue/tabview';
// import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
// import Tree from 'primevue/tree';
// import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
// import '@fullcalendar/core/main.min.css';
// import '@fullcalendar/daygrid/main.min.css';
// import '@fullcalendar/timegrid/main.min.css';
import './assets/layout/layout.scss';

Amplify.configure(awsconfig);
// Amplify.configure({
//         API: {
//                 graphql_headers: async () => ({
//                         'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
//                 })
//         }
// });

Vue.use(ToastService);
Vue.use(PrimeVue);
Vue.use(WebCam);
// Vue.use(VueGtag, {
//         config: {id: "UA-208859758-1"},
//         enabled: false
// });
Vue.directive('tooltip', Tooltip);




// if (initLogRocket) {
//         LogRocket.init('khecro/rychle-fundinfo');
// }

// console.log("Host for MSAL" + hostUrl)

// Vue.use(msal, {
// 	auth: {
// 		clientId: "c1c61a02-1fae-487f-bf3d-5c87bc245141",
// 		tenantId: "813030e3-6655-4fd7-a9ff-78e355a50921",
// 		redirectUri: hostUrl
// 	}
// })


Vue.config.productionTip = false;

Vue.component("vue-json-pretty", VueJsonPretty)

// Vue.component('Accordion', Accordion);
// Vue.component('AccordionTab', AccordionTab);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('Button', Button);
Vue.component('Calendar', Calendar);
Vue.component('Card', Card);
// Vue.component('Carousel', Carousel);
// Vue.component('Chart', Chart);
Vue.component('Checkbox', Checkbox);
// Vue.component('Chips', Chips);
Vue.component('Column', Column);
// Vue.component('ContextMenu', ContextMenu);
Vue.component('DataTable', DataTable);
Vue.component('DataView', DataView);
Vue.component('DataViewLayoutOptions', DataViewLayoutOptions);
Vue.component('Dialog', Dialog);
Vue.component('Dropdown', Dropdown);
Vue.component('Editor', Editor);
// Vue.component('Fieldset', Fieldset);
// Vue.component('FileUpload', FileUpload);
// Vue.component('FullCalendar', FullCalendar);
Vue.component('Inplace', Inplace);
Vue.component('InputSwitch', InputSwitch);
Vue.component('InputText', InputText);
Vue.component('InputMask', InputMask);
Vue.component('Listbox', Listbox);
// Vue.component('MegaMenu', MegaMenu);
Vue.component('Menu', Menu);
Vue.component('Menubar', Menubar);
Vue.component('Message', Message);
Vue.component('MultiSelect', MultiSelect);
// Vue.component('OrderList', OrderList);
// Vue.component('OrganizationChart', OrganizationChart);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('Paginator', Paginator);
Vue.component('Panel', Panel);
// Vue.component('PanelMenu', PanelMenu);
Vue.component('Password', Password);
Vue.component('PickList', PickList);
Vue.component('ProgressBar', ProgressBar);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('RadioButton', RadioButton);
Vue.component('Rating', Rating);
Vue.component('SelectButton', SelectButton);
// Vue.component('Slider', Slider);
Vue.component('Sidebar', Sidebar);
Vue.component('SplitButton', SplitButton);
Vue.component('Steps', Steps);
// Vue.component('TabMenu', TabMenu);
// Vue.component('TabView', TabView);
// Vue.component('TabPanel', TabPanel);
Vue.component('Textarea', Textarea);
// Vue.component('TieredMenu', TieredMenu);
Vue.component('Toast', Toast);
Vue.component('Toolbar', Toolbar);
Vue.component('ToggleButton', ToggleButton);
// Vue.component('Tree', Tree);
// Vue.component('TreeTable', TreeTable);
Vue.component('TriStateCheckbox', TriStateCheckbox);


new Vue({
        router,
        store,
        render: h => h(App)
}).$mount('#app');


I18n.putVocabularies(dict);
I18n.setLanguage('cs');


// AmplifyEventBus.$on("authState", info => {
// 	console.log('AUTH: event emitted by an Amplify component: ' + info);
// });
